import React, { useState, useEffect } from 'react';
import About from './About/About';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { aboutData, footerData } from '../mock/data';

function App() {
  const [about, setAbout] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setAbout({ ...aboutData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ about, footer }}>
      <About />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
