import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="Hi, my name is Mert Ciflikli" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info" style={{ textAlign: 'left' }}>
                <p className="about-wrapper__info-text mt-5">
                  As a passionate and self-taught full stack software engineer, I am dedicated to
                  crafting clean, well-organized code that prioritizes maintainability and
                  scalability.
                </p>
                <p className="about-wrapper__info-text">
                  My love for TypeScript runs deep, and I continually seek opportunities to expand
                  my knowledge of type-level TypeScript, pushing its boundaries to solve complex
                  problems. By leveraging its capabilities, I strive to create robust, reliable, and
                  maintainable code. Additionally, my enthusiasm for open-source software drives me
                  to actively engage with projects that pique my curiosity.
                </p>

                <p className="about-wrapper__info-text d-flex flex-row">
                  In my free time, you can find me hitting the gym and going for runs. Additionally,
                  I enjoy producing music with Elektron Analog Rytm.
                </p>
                <div className="d-flex flex-row">
                  {resume && (
                    <span className="d-flex mt-3">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--resume"
                        href={resume}
                      >
                        Resume
                      </a>
                    </span>
                  )}
                  {
                    <span className="d-flex mt-3 ml-3">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--resume"
                        href="https://blog.mertciflikli.com/"
                      >
                        Blog
                      </a>
                    </span>
                  }
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
