import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta
          name="image"
          property="og:image"
          content="https://mertciflikli.com/static/921b60177da4e7c7311525548c763f4f/73c85/profile.png"
        />
        <meta charSet="utf-8" />
        <title>{title || 'Mert Ciflikli'}</title>
        <html lang={lang || 'en'} />
        <meta
          name="description"
          content={
            description || 'Mert Ciflikli is a software engineer who lives and works in Berlin.'
          }
        />
      </Helmet>
      <App />
    </>
  );
};
